<template>
  <div class="login-layout">
    <div class="header">
			<div class="wrapper">
				<div class="left">
				  <img src="@/assets/img/logo.png" alt="" />
				  <em>|</em>
				  <span v-if="route == '/login'">欢迎登录</span>
<!--				  <span v-if="route == '/register'">欢迎注册</span>-->
				  <span v-if="route == '/password'">设置新密码</span>
				</div>
				<div class="right">
				  <router-link to="/login" v-if="route == '/register'"
				    >已有账号？去<span>登录</span></router-link
				  >
				  <router-link to="/myhome" v-if="route == '/password'"
				    >我的主页</router-link
				  >
				</div>
			</div>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="footer">
      <P>Copyright © 2023 版权所有 青岛众创版权所有 鲁ICP备2023028523号-1<br />
          鲁公网安备 37021202001491号</P>
				<img src="../../assets/img/QRcode.png"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: null,
    };
  },
  watch: {
    $route() {
      this.route = this.$route.path;
    },
  },
  created() {
    this.route = this.$route.path;
  },
};
</script>

<style lang="scss" scoped>
.login-layout {
	position: relative;
  width: 100vw;
  height: 100vh;
	min-height: 900px;
  background: url("~@/assets/img/login-bg.jpg") no-repeat;
  background-size: 100% 100%;
  .header {
    background:$main-color;
    color: #fff;
    padding: 35px 80px;
		.wrapper{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
    .left {
      font-size: 24px;
      display: flex;
      align-content: center;
      line-height: 40px;
      em {
        margin: 0 20px;
      }
      span {
      }
    }
    .right {
      a {
        color: #fff;
        span {
          color: #ff7723;
        }
      }
    }
  }
  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 40px 0 30px 0;
    text-align: center;
    background: rgba($color: #fff, $alpha: 0.7);
		display: flex;
		align-items: center;
		justify-content: center;
		color: $main-color;
		img{
			width: 64px;
			margin: 0 0 0 30px;
		}
  }
}
</style>
